import { useState } from 'react';
import { transfer } from './Web3Service';
import ReCAPTCHA from 'react-google-recaptcha';

function App() {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("POL");

  function onBtnClick() {
    if (!/^.{2,}@.{2,}\..{2,}$/.test(email))
      return setMessage("E-mail inválido, tente novamente");

    if (captcha) {
      setMessage("Conectando à carteira...aguarde...");
      transfer(email, tokenSymbol)
        .then(result => {
          setMessage(`${tokenSymbol} ${result.amount} enviados para ${localStorage.getItem("wallet")}. Tx: <a href='${result.txUrl}' target='_blank'>${result.tx}</a>`);
          setEmail("");
        })
        .catch(err => setMessage(err.response ? JSON.stringify(err.response.data) : err.message));
    }
    else
      setMessage("Marque a caixa 'eu não sou um robô' primeiro!");
  }

  function onEmailChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setEmail(evt.target.value);
  }

  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <header className="mb-auto">
        <div>
          <h3 className="float-md-start mb-0">LuizTools Faucet</h3>
          <nav className="nav nav-masthead justify-content-center float-md-end">
            <a className="nav-link fw-bold py-1 px-0 active" aria-current="page" href="/">Home</a>
            <a className="nav-link fw-bold py-1 px-0" href="https://www.luiztools.com.br/meus-livros">Livros</a>
            <a className="nav-link fw-bold py-1 px-0" href="https://www.luiztools.com.br/meus-cursos">Cursos</a>
            <a className="nav-link fw-bold py-1 px-0" href="https://www.luiztools.com.br/sobre">Sobre</a>
          </nav>
        </div>
      </header>

      <main className="px-3">
        <h1>Obtenha tokens {tokenSymbol}</h1>
        <p className="lead">Selecione a moeda, informe seu email de aluno e conecte sua MetaMask ou Brave Wallet para receber. Uma vez por dia, receba moedas para fazer as aulas dos meus cursos. </p>
        <div className="input-group mb-3">
          <select name="symbol" id="symbol" className="form-control" onChange={(evt) => setTokenSymbol(evt.target.value)}>
            <option value="POL">POL</option>
            <option value="ETH">ETH</option>
            <option value="BNB">BNB</option>
          </select>
          <input type="text" typeof="email" value={email} className="form-control w-50" placeholder="Digite o email usado na inscrição do curso" onChange={onEmailChange} />
          <a href="#" onClick={onBtnClick} className="btn btn-lg btn-secondary fw-bold" style={{ color: "white" }}>
            <img src="/assets/metamask.svg" alt="MetaMask logo" width="48" className='me-2' />
            Obter {tokenSymbol}
          </a>
        </div>
        <div style={{ display: "inline-flex" }}>
          <ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`} onChange={value => setCaptcha(value || "")} />
        </div>
        <p className="lead">
          {message}
        </p>
        <hr className="my-5" />
        <h1>Quer mais {tokenSymbol}?</h1>
        <p className="lead">Use os faucets abaixo para conseguir mais moedas, mesmo que você não seja meu aluno.</p>
        <div className='row mt-4'>
          <div className='col'>
            <a href="https://faucet.stakepool.dev.br" className="btn btn-large btn-primary p-4" target="_blank">StakePool (POL)</a>
          </div>
          <div className="col">
            <a href="https://faucets.chain.link" className="btn btn-large btn-primary p-4" target="_blank">Chainlink (Várias)</a>
          </div>
          <div className="col">
            <a href="https://faucet.polygon.technology" className="btn btn-large btn-primary p-4" target="_blank">Polygon (POL)</a>
          </div>
          <div className="col">
            <a href="https://sepolia-faucet.pk910.de/#/" className="btn btn-large btn-primary p-4" target="_blank">Sepolia POW (ETH)</a>
          </div>
        </div>
        <div className='row mt-4'>
          <div className="col">
            <a href="https://www.bnbchain.org/en/testnet-faucet" className="btn btn-large btn-primary p-4" target="_blank">BNB Chain (BNB)</a>
          </div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
        </div>
      </main>

      <footer className="mt-auto text-white-50">
        <p>Construído por <a href="https://www.luiztools.com.br" className="text-white">LuizTools</a>. v1.19</p>
      </footer>
    </div>
  );
}

export default App;
