import { ethers } from 'ethers';
import axios from 'axios';

const networks : any = {
    "ETH": {
        chainId: "0xaa36a7",
        chainName: "Ethereum Sepolia",
        symbol: "ETH",
        decimals: 18,
        rpc: "https://sepolia.infura.io/v3/b56aafe701cb4784a3c62d8f7e67ffcb",
        explorer: "https://sepolia.etherscan.io"
    },
    "POL": {
        chainId: "0x13882",
        chainName: "Polygon Amoy",
        symbol: "POL",
        decimals: 18,
        rpc: "https://rpc-amoy.polygon.technology/",
        explorer: "https://amoy.polygonscan.com"
    },
    "BNB": {
        chainId: "0x61",
        chainName: "BNB Chain Testnet",
        symbol: "BNB",
        decimals: 18,
        rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        explorer: "https://testnet.bscscan.com"
    },
}

async function networkChecks(provider: ethers.BrowserProvider, symbol: string) {

    const network = networks[symbol];

    const chainId = await window.ethereum.request({
        "method": "eth_chainId",
        "params": [],
    });

    if (chainId == network.chainId)
        return true;

    await provider.send('wallet_addEthereumChain', [
        {
            chainId: network.chainId,
            chainName: network.chainName,
            nativeCurrency: {
                name: network.symbol,
                symbol: network.symbol,
                decimals: network.decimals
            },
            rpcUrls: [network.rpc],
            blockExplorerUrls: [network.explorer]
        }])

    await provider.send("wallet_switchEthereumChain", [{ chainId: network.chainId }]);
}

export async function transfer(email: string, symbol: string) {
    const nextTransfer = localStorage.getItem("nextTransfer");
    if (nextTransfer && parseInt(nextTransfer) > Date.now())
        throw new Error(`Você não pode obter tokens mais de uma vez por dia. Tente novamente amanhã.`);

    if (!window.ethereum) throw new Error(`MetaMask não encontrada.`);

    const provider = new ethers.BrowserProvider(window.ethereum);

    
    const accounts = await provider.send("eth_requestAccounts", []);
    if (!accounts || !accounts.length) throw new Error(`Conta não autorizada!`);

    const wallet = accounts[0].toLowerCase();

    const balance = await provider.getBalance(wallet);
    const etherBalance = ethers.formatEther(balance);
    if (Number(etherBalance) >= 0.1)
        throw new Error(`Você já tem saldo suficiente na sua carteira (${symbol} ${etherBalance}) para fazer a aula de hoje.`);

    const oldWallet = localStorage.getItem("wallet");
    if (oldWallet && oldWallet !== wallet)
        throw new Error(`Esta não é a carteira autorizada para esse aluno.`);

    await networkChecks(provider, symbol);

    const timestamp = Date.now();
    const signer = await provider.getSigner();

    const message = email + " at timestamp " + timestamp;
    const secret = await signer.signMessage(message);

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/transfer`, {
        email,
        wallet,
        secret,
        timestamp,
        symbol
    });

    localStorage.setItem("wallet", wallet);
    localStorage.setItem("nextTransfer", `${Date.now() + (1000 * 60 * 60 * 24)}`);

    response.data.txUrl = networks[symbol].explorer + "/tx/" + response.data.tx;

    return response.data;
}